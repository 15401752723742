// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmAccountFromMail_header__1XVcp {\n    margin-top: 109px;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 34px;\n    line-height: 38px;\n    color: #2C2C2C;\n    margin-bottom: 8px;\n}\n\n\n\n", "",{"version":3,"sources":["webpack://src/screens/ConfirmAccountFromMail/ConfirmAccountFromMail.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".header {\n    margin-top: 109px;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 34px;\n    line-height: 38px;\n    color: #2C2C2C;\n    margin-bottom: 8px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "ConfirmAccountFromMail_header__1XVcp"
};
export default ___CSS_LOADER_EXPORT___;
