// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iframe_iframeContainer__2VtTA {\n  width: 100%;\n  overflow: hidden;\n  position: relative;\n}\n\n.iframe_iframeBlock__CcJ2- {\n  pointer-events: none;\n  margin-top: -30px;\n  margin-left: -8px;\n  z-index: 2;\n  overflow: hidden;\n  height: 455px;\n  width: calc(100% + 17px);\n}\n", "",{"version":3,"sources":["webpack://src/components/Iframe/iframe.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;EACjB,UAAU;EACV,gBAAgB;EAChB,aAAa;EACb,wBAAwB;AAC1B","sourcesContent":[".iframeContainer {\n  width: 100%;\n  overflow: hidden;\n  position: relative;\n}\n\n.iframeBlock {\n  pointer-events: none;\n  margin-top: -30px;\n  margin-left: -8px;\n  z-index: 2;\n  overflow: hidden;\n  height: 455px;\n  width: calc(100% + 17px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframeContainer": "iframe_iframeContainer__2VtTA",
	"iframeBlock": "iframe_iframeBlock__CcJ2-"
};
export default ___CSS_LOADER_EXPORT___;
