// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes Spinner_rotating__1AVxB {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n.Spinner_rotating__1AVxB {\n  animation: Spinner_rotating__1AVxB 2s linear infinite;\n\n  height: 100%\n}\n#Spinner_preloader__2Ierl {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  z-index: 100000000000000000000;\n  background-color: white;\n  top: 0;\n  left: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/Spinner/Spinner.module.css"],"names":[],"mappings":"AAYA;EACE;IAKE,uBAAuB;EACzB;EACA;IAKE,yBAAyB;EAC3B;AACF;AACA;EAKE,qDAAsC;;EAEtC;AACF;AACA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,uBAAuB;EACvB,MAAM;EACN,OAAO;AACT","sourcesContent":["@-webkit-keyframes rotating /* Safari and Chrome */ {\n  from {\n    -webkit-transform: rotate(0deg);\n    -o-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  to {\n    -webkit-transform: rotate(360deg);\n    -o-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n@keyframes rotating {\n  from {\n    -ms-transform: rotate(0deg);\n    -moz-transform: rotate(0deg);\n    -webkit-transform: rotate(0deg);\n    -o-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  to {\n    -ms-transform: rotate(360deg);\n    -moz-transform: rotate(360deg);\n    -webkit-transform: rotate(360deg);\n    -o-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n.rotating {\n  -webkit-animation: rotating 2s linear infinite;\n  -moz-animation: rotating 2s linear infinite;\n  -ms-animation: rotating 2s linear infinite;\n  -o-animation: rotating 2s linear infinite;\n  animation: rotating 2s linear infinite;\n\n  height: 100%\n}\n#preloader {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  z-index: 100000000000000000000;\n  background-color: white;\n  top: 0;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rotating": "Spinner_rotating__1AVxB",
	"preloader": "Spinner_preloader__2Ierl"
};
export default ___CSS_LOADER_EXPORT___;
