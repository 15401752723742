// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Success_base_container__2nH-S {\n  margin-bottom: 15px;\n}\n\n.Success_header__1HCSh {\n  margin-top: 50px;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 34px;\n  line-height: 38px;\n  color: #2c2c2c;\n  margin-bottom: 8px;\n}\n\n.Success_label1__3IzQH {\n  font-style: normal;\n  font-weight: 800;\n  font-size: 17px;\n  color: #2c2c2c;\n  margin-bottom: 104px;\n}\n\n.Success_label2__11ZJ8 {\n  margin-left: 42px;\n  margin-right: 42px;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 24px;\n  color: #2c2c2c;\n  margin-bottom: 18px;\n}\n\n@media (max-width: 420px) {\n  .Success_header__1HCSh {\n    margin-top: 50px;\n  }\n\n  .Success_label1__3IzQH {\n    margin-bottom: 50px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/screens/Success/Success.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".base_container {\n  margin-bottom: 15px;\n}\n\n.header {\n  margin-top: 50px;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 34px;\n  line-height: 38px;\n  color: #2c2c2c;\n  margin-bottom: 8px;\n}\n\n.label1 {\n  font-style: normal;\n  font-weight: 800;\n  font-size: 17px;\n  color: #2c2c2c;\n  margin-bottom: 104px;\n}\n\n.label2 {\n  margin-left: 42px;\n  margin-right: 42px;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 24px;\n  color: #2c2c2c;\n  margin-bottom: 18px;\n}\n\n@media (max-width: 420px) {\n  .header {\n    margin-top: 50px;\n  }\n\n  .label1 {\n    margin-bottom: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base_container": "Success_base_container__2nH-S",
	"header": "Success_header__1HCSh",
	"label1": "Success_label1__3IzQH",
	"label2": "Success_label2__11ZJ8"
};
export default ___CSS_LOADER_EXPORT___;
