// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Back_back__wkzGG {\n  text-align: left;\n  padding-top: 17px;\n  margin-left: 16px;\n  width: -moz-fit-content;\n  width: fit-content;\n  height: -moz-fit-content;\n  height: fit-content;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://src/components/Back/Back.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,uBAAkB;EAAlB,kBAAkB;EAClB,wBAAmB;EAAnB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".back {\n  text-align: left;\n  padding-top: 17px;\n  margin-left: 16px;\n  width: fit-content;\n  height: fit-content;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back": "Back_back__wkzGG"
};
export default ___CSS_LOADER_EXPORT___;
